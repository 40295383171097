<template>
<div class="page-view">
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span class="page-title">RootVariables</span>
    </div>
    <div class="text-item">
      <div class="dashboard-table" style="margin-top: 20px">
        <el-table
          border
          v-loading="loading"
          :data="roots"
          style="width: 50%"
        >
          <el-table-column
          prop="name"
          label="Name"
          >

          </el-table-column>
          <el-table-column
              prop="value"
              label="Value"
          >
            <template slot-scope="scope" style="text-align: right">
              <p v-if="scope.row.id != edit">{{scope.row.value}}</p>
              <el-input v-if="scope.row.id == edit" style="width: 200px" v-model="editableValue"></el-input>
            </template>

          </el-table-column>
          <el-table-column
              label="Action"
              width="100"
          >
            <template slot-scope="scope">
              <el-button v-if="scope.row.id != edit" type="primary" icon="el-icon-edit" circle @click.stop="update(scope.row)"></el-button>
              <div style="display: flex">
                <el-button v-if="scope.row.id == edit" type="success" icon="el-icon-check" circle @click.stop="updateVariable(scope.row)"></el-button>
                <el-button v-if="scope.row.id == edit" type="danger" icon="el-icon-close" circle @click.stop="cancelEditVariable"></el-button>
              </div>
            </template>

          </el-table-column>
        </el-table>
      </div>
    </div>
  </el-card>
</div>
</template>

<script>

export default {
  name: "RootVariables",
  data(){
    return{
      loading:false,
      roots:[],
      edit:null,
      editableValue:null
    }
  },
  methods:{
    update(val){
      this.editableValue = val.value
      this.edit = val.id
    },
    updateVariable(v){
      this.$http.post('Admin/EditRootVariable',{
        Id: v.id,
        Value : this.editableValue
      })
      .then(res=>{
        if (res.result.isSuccess) {
          this.edit = null;
          v.value = this.editableValue;
        } else {
          this.$notify.error({
            title: 'Error',
            message: res.response.data.error.message
          });
        }
      })
      .catch((err) =>{
        this.$notify.error({
          title: 'Error',
          message: err
        });
      })

    },
    cancelEditVariable() {
      this.edit = false
    },
    async getRootVariables(){
      this.loading = true
      try {
        const res = await this.$http.get('Admin/GetRootVariables');
        this.roots = res.result;
      } catch (e) {
        console.log(e)
        this.$notify.error({
          title: 'Error',
          message: e.response.data.error.message
        });
      } finally {
        this.loading= false;
      }
    },
  },
  created() {
    this.getRootVariables()
  }
}
</script>

<style scoped>

</style>